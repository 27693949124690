<template>
    <div>
    <material-card class="mx-4"
                   :title="get_title"
                   text="Invoice / Quote data for factories and customers">

        <template v-if="!!order" :slot="'header_actions'">

            <v-btn small fab class="ml-2"
                   v-tooltip="'Reload order information'"
                   @click="load_order">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-btn small fab class="ml-2"
                   v-tooltip="'Open chat for order'"
                   @click="chat_opened = !chat_opened">
                <v-icon>mdi-message</v-icon>
            </v-btn>

            <v-btn small fab
                   class="outline_btn stage_button ml-2"
                   :class="'stage_btn-' + get_stage">
                {{ get_stage }}
            </v-btn>
        </template>

        <v-container>
            <v-row v-if="!order && !!is_loading">
                <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                />
            </v-row>

            <template v-if="!!order && !is_loading">

                <v-row>
                    <h3 class="my-0">Factories</h3>
                    <v-spacer/>
                    <v-btn x-small fab color="primary ml-2"
                           :disabled="factories_selected.length === 0"
                           @click="clone_orders()"
                           v-tooltip="'Copy / Clone Selected To New PI'">
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>

<!--                    <v-btn x-small fab color="primary ml-2"-->
<!--                           :disabled="factories_selected.length === 0"-->
<!--                           @click="move_orderlines()"-->
<!--                           v-tooltip="'Move products from selected to new PI'">-->
<!--                        <v-icon>mdi-content-duplicate</v-icon>-->
<!--                    </v-btn>-->
                </v-row>

                <v-divider class="mt-1"/>

                <v-simple-table dense>
                    <thead>
                    <tr>
                        <th class="text-center"><v-icon>mdi-checkbox-marked-outline</v-icon></th>
                        <th class="text-center">#</th>
                        <th class="text-center">Name</th>
                        <th class="text-center">No of Products</th>
                        <th class="text-center">Shipping Port</th>
                        <th class="text-center">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(factory, key) in order.factories" :key="key">
                        <td class="text-center">
                            <v-switch @change="add_remove_selected_factory(factory)"
                                      class="mx-2" hide-details>
                            </v-switch>
                        </td>
                        <td>{{ factory.id }}</td>
                        <td>{{ factory.name }}</td>
                        <td class="text-center"> {{ order.orderlines.filter(o => o.product.factory.id === factory.id).length }}</td>
                        <td class="text-center">{{ factory.shipping_port }}</td>
                        <td class="text-center">
                            <v-btn fab text small
                                   color="blue-grey darken-4"
                                   @click="open_factory_mail(factory)">
                                <v-icon> mdi-email</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>

                <v-row class="title mt-1">Items</v-row>

                <v-simple-table dense class="invoice_items_table">
                    <thead>
                        <tr>
                            <th class="text-left">Item Number</th>
                            <th class="text-left">Img</th>
                            <th class="text-left">Name</th>
                            <th class="text-left">Fnl QTY Delivered</th>

                            <th class="text-center">
                                Factory FOB
                            </th>
                            <th class="text-center">
                                Customer FOB
                            </th>
                            <th class="text-center">
                                Factory Total
                            </th>
                            <th class="text-center">
                                Customer Total
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(invoice, index) in order.orderlines" :key="index">
                            <td>
                                {{ invoice.product.item_number }}
                            </td>
                            <td>
                                <div v-if="invoice.product.album">
                                    <v-img
                                            v-if="invoice.product.album.files.length"
                                            class="white--text"
                                            width="100px"
                                            :src="invoice.product.album.files[0].url"
                                    >
                                    </v-img>
                                </div>

                            </td>
                            <td>
                                {{ invoice.product.name }}
                            </td>

                            <td class="text-center">
                                <v-text-field
                                        v-model="invoice.final_qty_delivered"
                                        label=""
                                        type="number"
                                        @keyup="calculateTotals(invoices)"
                                ></v-text-field>
                            </td>
                            <td class="text-center">
                                <v-text-field
                                        v-model="invoice.factory_fob_price"
                                        label=""
                                        :disabled="true"
                                        type="number"
                                        @keyup="calculateTotals(invoices)"
                                ></v-text-field>
                            </td>
                            <td class="text-center">
                                <v-text-field
                                        v-model="invoice.organisation_fob_price"
                                        label=""
                                        :disabled="true"
                                        type="number"
                                        @keyup="calculateTotals(invoices)"
                                ></v-text-field>
                            </td>
                            <td class="text-center">
                                {{ invoice.factory_fob_price * invoice.qty | currency }}
                            </td>
                            <td class="text-center">
                                {{ invoice.organisation_fob_price * invoice.qty | currency }}
                            </td>
                        </tr>
                        </tbody>
                </v-simple-table>

                <v-row class="title mt-8">Additional Information</v-row>

                <v-row>
                    <v-simple-table dense>
                        <thead>
                        <tr>
                            <th style="width:150px;" class="text-left">Total CTN</th>
                            <th style="width:130px;">
                                {{ total_ctn }}
                            </th>
                        </tr>
                        <tr>
                            <th class="text-left">Final Additional Customer Costs</th>
                            <th>
                                <v-text-field
                                        v-model="order.final_organisation_additional_costs"
                                        label=""
                                        type="number"
                                />
                            </th>
                            <th>
                                <v-textarea
                                        v-show="order.final_organisation_additional_costs > 0"
                                        label="Reason"
                                        :auto-grow="true"
                                        :flat="true"
                                        :single-line="true"
                                />
                            </th>
                        </tr>
                        <tr>
                            <th class="text-left">
                                Final Additional Factory Costs
                            </th>
                            <th>
                                <v-text-field
                                        v-model="order.final_factory_additional_costs"
                                        label=""
                                        type="number"
                                />
                            </th>
                            <th>
                                <v-textarea
                                        v-show="order.final_factory_additional_costs > 0"
                                        label="Reason"
                                        :auto-grow="true"
                                        :flat="true"
                                        :single-line="true"
                                />
                            </th>
                        </tr>

                        <tr>
                            <th class="text-left">
                                Factory Currency
                            </th>
                            <th>
                                <v-select
                                        v-model="order.currency_used"
                                        :items="currencies"
                                        label=""
                                />
                            </th>
                        </tr>

                        <tr>
                            <th class="text-left">
                                <v-switch
                                        v-model="order.has_rebate"
                                        :label="'Has Rebate'"
                                />
                            </th>
                            <th>
                                <v-text-field
                                        v-show="order.has_rebate"
                                        v-model="order.rebate"
                                        label="Rebate %"
                                        type="number"
                                />
                            </th>
                        </tr>

                        <tr>
                            <th class="text-left">
                                Total Carton CBM
                            </th>
                            <th>
                                {{ fix_number(total_carton_cbm, 2) }}
                            </th>
                        </tr>

                        <tr>
                            <th class="text-left">
                                Total Item CBM
                            </th>
                            <th>
                                {{ fix_number(total_item_cbm, 2) }}
                            </th>
                        </tr>
                        <tr>
                            <th>Final Exchange Rate</th>
                            <th>
                                <v-text-field
                                        v-model="order.final_exchange_rate"
                                        type="number"
                                        label="Exchange Rate"
                                        required
                                />
                            </th>
                        </tr>

                        <tr>
                            <th class="text-left">
                                Customer Total
                            </th>
                            <th>
                                {{ client_total | currency }}
                            </th>
                            <th>
                                ¥{{ fix_number((client_total * (order.final_exchange_rate || 1)), 2) }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                Factory Total
                            </th>
                            <th>
                                {{ factory_total | currency }}
                            </th>
                            <th>
                                ¥{{ fix_number((factory_total * (order.final_exchange_rate || 1)), 2) }}
                            </th>
                        </tr>
                        </thead>
                    </v-simple-table>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                                v-model="order.pi_number"
                                :counter="100"
                                label="PI Number"
                                :disabled="true"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                                v-model="order.po_number"
                                :counter="100"
                                label="PO Number"
                                :disabled="true"
                        />
                    </v-col>
                </v-row>

                <v-divider/>

                <v-btn block color="primary" class="mr-5"
                       @click="save_order()">
                    Save
                </v-btn>
            </template>
        </v-container>

    </material-card>


        <div class="order-chat-wrapper" v-if="chat_opened">
            <w-order-chat :selected_invoice="order" @close_chat="chat_opened = false"/>
        </div>

    </div>
</template>

<script>

    import { calc_item_cbm, calc_carton_cbm, calc_item_ttl, calc_carton_ttl,
        calc_total_master_ctn, calc_factory_target_fob, calc_customer_target_fob, calc_line_department_increase,
        calc_line_department_increase_success, customer_target_price_percentage_difference, fix_number}
        from "@/plugins/wellstar_functions"

    export default {
        data() {
            return {
                order_old: {},
                order: {is_loading:false},
                is_loading: true,
                chat_opened: false,
                factories_selected: [],
                invoice_id: false,
                partners: [],
                currencies: [
                    '$',
                    '¥',
                    '€',
                    '£'
                ],
                factories: [],
                email_dialog: false,
                factory: {},
            }
        },
        computed: {
            allowed_access_to_final: function () {
                return this.$store.getters.user_groups.filter(value => [1, 2, 15].includes(value));
            },
            get_title: function () {
                return (this.order) ? ((this.order.stage < 5) ? 'Quote: ' : 'Product Invoice: ') + this.order.pi_number : "Product Invoide: Edition";
            },
            get_stage: function () {
                return (this.order) ? this.order.stage : 1;
            },
            total_ctn: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (line['pieces_per_master_carton'] || 0), 0) : 0;
            },
            total_item_cbm: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (this.calc_item_ttl(line) || 0), 0) : 0;
            },
            total_carton_cbm: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (this.calc_carton_ttl(line) || 0), 0) : 0;
            },
            client_total: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (parseInt(line.qty) * parseFloat(line.organisation_fob_price) || 0), 0) : 0;
            },
            factory_total: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (parseInt(line.qty) * parseFloat(line.factory_fob_price) || 0), 0) : 0;
            }
        },
        created() {
            this.invoice_id = this.$route.params.invoice_id;
            this.load_order();
        },
        methods: {
            async load_order() {
                this.is_loading = true;

                // Getting previous data
                this.partners = await this.$hget('/admins/partners').then(r => r.data) || [];
                this.customers = await this.$hget('/logistics/customers/').then(r => r.data) || [];

                console.log(this.customers)

                // Getting order
                this.order = await this.$hget('order/' + this.invoice_id).then(response => response.data);


                // Getting order values as js objects
                this.customer = this.customers.find(o => o.id === this.order.organisation);

                if (typeof this.order.partner !== 'undefined')
                    this.order.partner = this.partners.find(o => o.id === this.order.partner);


                console.log("Order:", this.order);

                this.order_old = Object.assign(this.order, {});
                this.is_loading = false;
            },
            async save_order(){
                this.is_loading = true;
                await this.$hpost('/order/' + this.$route.params.invoice_id + '/', {order: this.order});

                this.load_order();

                this.$notify({
                    title: 'Success:',
                    message: 'successfully saved PI',
                    group: 'notifications',
                    position: 'top right'
                });

                this.is_loading = false;
            },

            async clone_orders() {
                let confirm_text = 'This will make another PI with selected factory products and also keep them in this PI are you sure you want to continue?'

                if (confirm(confirm_text)) {

                    if (!this.factories_selected.length) {
                        this.$notify({
                            title: 'Error:',
                            text: 'No Factories Selected',
                            group: 'notifications',
                            position: 'top right'
                        });
                    } else {
                        let send_data = {
                            factories: this.factories_selected.map(f => f.id),
                            invoice_id: this.order.invoice_id
                        };
                        let new_pi_number = await this.$hpost('/admins/clone-pi/', send_data)
                            .then(r => r.data);

                        new_pi_number = new_pi_number.new_pi_number;
                        this.$notify({
                            title: 'Success:',
                            text: 'Successfully cloned Products under the new QT number: ' + new_pi_number,
                            group: 'notifications',
                            position: 'top right'
                        });

                        this.load_order();
                    }
                }
            },

            async move_orderlines() {
                if (!this.factories_selected.length) {
                    this.$notify({
                        title: 'Error:',
                        text: 'No Factories Selected',
                        group: 'notifications',
                        position: 'top right'
                    });
                } else if (this.factories_selected.length === this.order.factories.length) {
                    this.$notify({
                        title: 'Error:',
                        text: 'You can\'t move all factories',
                        group: 'notifications',
                        position: 'top right'
                    });
                } else {
                    let new_pi_number = await this
                        .$hpost('/admins/move-pi/', {
                            factories: this.factories_selected.map(f => f.id),
                            invoice_id: this.order.invoice_id
                        })
                        .then(r => r.data);
                    new_pi_number = new_pi_number.new_pi_number;
                    this.$notify({
                        title: 'Success:',
                        text: 'Successfully Moved Products under the new QT number: ' + new_pi_number,
                        group: 'notifications',
                        position: 'top right'
                    });
                    this.load_order();
                }
            },

            open_factory_mail(factory) {
                this.factory = factory;
                this.email_dialog = true;
            },
            send_factory_email(factory) {
                factory.invoice_id = this.invoice_id;

                this.$http.post(
                    this.$http.defaults.baseURL + '/admins/send-factory-email/', factory).then((response) => {
                    this.$notify({
                        title: 'Success:',
                        message: 'successfully sent email',
                        group: 'notifications',
                        position: 'top right'
                    });
                    this.email_dialog = false;

                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },

            async clone_all_orders() {
                let confirm_text = 'This will make another PI with selected factory products and also keep them in this PI are you sure you want to continue?'

                if(confirm(confirm_text)) {
                    let send_data = {
                        invoice_id: this.invoices[0].invoice_id
                    };
                    let new_pi_number = await this.$hpost('/admins/clone-all-pi/', send_data)
                        .then(r => r.data);

                    new_pi_number = new_pi_number.new_pi_number;
                    this.$notify({
                        title: 'Success:',
                        text: 'Successfully cloned Products under the new QT number: ' + new_pi_number,
                        group: 'notifications',
                        position: 'top right'
                    });

                }
            },

            calc_item_cbm(line) {
                return calc_item_cbm(line);
            },
            calc_carton_cbm(line) {
                return calc_carton_cbm(line);
            },
            calc_item_ttl(line){
                return calc_item_ttl(line);
            },
            calc_carton_ttl(line){
                return calc_carton_ttl(line);
            },
            calc_total_master_ctn(line){
                return calc_total_master_ctn(line);
            },
            calc_factory_target_fob(line) {
                return calc_factory_target_fob(line);
            },
            calc_customer_target_fob(line) {
                return calc_customer_target_fob(line);
            },
            calc_line_department_increase(line){
                return calc_line_department_increase(line);
            },
            calc_line_department_increase_success(line){
                return calc_line_department_increase_success(line);
            },
            customer_target_price_percentage_difference(line){
                return customer_target_price_percentage_difference(line);
            },
            fix_number(number, to_pos)
            {
                number = parseInt(number);

                if(typeof number === undefined || !number)
                {
                    number = 0;
                }

                return number.toFixed(to_pos);
            }
        }
    }
</script>

<style lang="scss">

    $stages-base_color: #b2cee8 #E27E4B #e25662 #4c89be #f08686 #fcdf88 #18e2c4 #bdd6a5 #1dbf77 #e1e1e1 #888888 #5d5d5d #ffffff;
    $stages-highlight_color: #b2cee8 #E27E4B #e25662 #4c89be #f08686 #fcdf88 #18e2c4 #bdd6a5 #1dbf77 #e1e1e1 #888888 #5d5d5d #ffffff;

    @for $i from 1 through 13 {
        .stage_btn-#{$i} {
            border: 2px solid nth($stages-base_color, $i);
        }
    }

    .order-chat-wrapper {
        width: 400px;
        height: 500px;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 900;
        background: white;

        .close {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 901;
        }
    }


</style>
